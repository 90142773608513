import React, { useState } from "react";
import "./SliderItem.css";
import { useHistory } from "react-router-dom";
import { service } from "../../Network/service";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useDispatch } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import TooltipMsg from "./Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ToastsContainer, ToastsStore } from "react-toasts";
import freeIcon from "../../images/free.png";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

var showsImageUrl = "https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "12px",
    boxShadow: "1px 1px 9px",
  },
  customArrow: {
    color: "#fff",
  },
}));

const SliderItem = ({
  params,
  clickHandler,
  onClickTralier,
  categoryName,
  categoryId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [watchListmsg, setWatchListmsg] = useState(false);
  const classes = useStyles();

  const addtoMylistFunction = (show) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      service.addToMyPlayList(show.show_id, 1).then((response) => {
        clickHandler();
        setWatchListmsg("Added to watchlist");
        setTimeout(function () {
          setWatchListmsg(false);
        }, 1000);
      });
    } else {
      dispatch({ type: "SIGN_IN_BLOCK" });
    }
  };
  const removeFromMylistFunction = (show) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      service.addToMyPlayList(show.show_id, 0).then((response) => {
        clickHandler();
        setWatchListmsg("Removed from watchlist");
        setTimeout(function () {
          setWatchListmsg(false);
        }, 2000);
      });
    } else {
      dispatch({ type: "SIGN_IN_BLOCK" });
    }
  };

  const redirectToLogin = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    service.eraseCookie("showId");
    service.eraseCookie("isScreeningUser");
    service.eraseCookie("userName");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
    service.eraseCookie("subscriptionId");
    sessionStorage.removeItem("applaunch");
    setTimeout(() => {
      window.location.href = "/signin";
    }, 1500);
  };

  // *********

  const freeVideoPopup = (onClickYes, onClickNo) => {
    confirmAlert({
      title: "",
      message: "Want to watch ad-free?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            onClickYes();
          },
        },
        {
          label: "No",
          onClick: () => {
            onClickNo();
          },
        },
      ],
    });
  };

  // *********

  const onClickPlaybuttonNew = (ShowId, videoId) => {
    
    if (ShowId && videoId) {
      service.getShowDetails(ShowId).then((response) => {
        if (response.success == true && response.data && response.data.videos) {
          let videoList = response.data.videos.filter(
            (e) => e.video_id == videoId
          );
          if (
            videoList &&
            videoList[0] &&
            videoList[0].screening_enabled != true &&
            videoList[0].free_video != true
          ) {
            let movie = videoList[0];
            service.videoSubscription(movie.video_id).then((response) => {
              let videoDetails = response.data;
              let subFlag = true;
              let uId = service.getCookie("guestUserId");
              let user_id = service.getCookie("userId");
              if (user_id) {
                uId = user_id;
              }
              service.userSubscription(uId).then((useResponse) => {
                if (useResponse.success == true) {
                  var userData = useResponse.data;
                  if (useResponse.forcibleLogout === true) {
                    alert(
                      "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                    );
                    service.logoutAll(uId).then((res) => {
                      setTimeout(() => {
                        redirectToLogin();
                      }, 1000);
                    });
                  } else if (useResponse.session_expired === true) {
                    ToastsStore.warning("Sorry! Session Has Expired");
                    redirectToLogin();
                  } else {
                    videoDetails.map(function (subscription, index) {
                      if (useResponse.data.length == 0 && subFlag) {
                        subFlag = false;
                        service.setCookie("showId", ShowId, 10);
                        service.setCookie("videoId", movie.video_id, 10);
                        history.push({
                          pathname: "/SubscriptionList",
                          state: {
                            videoData: movie.video_id,
                          },
                        });
                      } else {
                        let subscribedVideo = userData.filter(
                          (e) =>
                            e.sub_id == subscription.publisher_subscription_id
                        );
                        if (
                          subscribedVideo.length == 0 &&
                          index + 1 < videoDetails.length
                        ) {
                          return;
                        }
                        if (
                          subscribedVideo.length == 0 &&
                          subFlag &&
                          index + 1 == videoDetails.length
                        ) {
                          subFlag = false;
                          service.setCookie("showId", ShowId, 10);
                          service.setCookie("videoId", movie.video_id, 10);
                          history.push({
                            pathname: "/SubscriptionList",
                            state: {
                              videoData: movie.video_id,
                            },
                          });
                        } else if (subFlag) {
                          subFlag = false;
                          service.setCookie("showId", ShowId, 10);
                          localStorage.setItem("ContinueWatching", "true");
                          localStorage.setItem("NoContinueWatchPopUp", "true");
                          history.push({
                            pathname: "/videoDetails",
                            state: { movie: movie, show_id: ShowId },
                          });
                        }
                      }
                    });
                  }
                }
              });
            });
          } else {
            if (
              videoList &&
              videoList[0] &&
              videoList[0].screening_enabled == true //screening room video
            ) {
              let movie = videoList[0];
              service.setCookie("showId", ShowId, 10);
              localStorage.setItem("ContinueWatching", "true");
              localStorage.setItem("NoContinueWatchPopUp", "true");
              history.push({
                pathname: "/videoDetails",
                state: { movie: movie, show_id: ShowId },
              });
            } else if (
              videoList &&
              videoList[0] &&
              videoList[0].free_video == true &&
              videoList[0].subscriptions &&
              videoList[0].subscriptions.length > 0 //free video  but have subscriptions
            ) {
              let uId = service.getCookie("guestUserId");
              let user_id = service.getCookie("userId");
              if (user_id) {
                uId = user_id;
              }
              let movie = videoList[0];
              const onClickNo = () => {
                service.setCookie("showId", ShowId, 10);
                localStorage.setItem("ContinueWatching", "true");
                localStorage.setItem("NoContinueWatchPopUp", "true");
                history.push({
                  pathname: "/videoDetails",
                  state: { movie: movie, show_id: ShowId },
                });
              };

              const onClickYes = () => {
                service.setCookie("showId", ShowId, 10);
                service.setCookie("videoId", movie.video_id, 10);
                history.push({
                  pathname: "/SubscriptionList",
                  state: {
                    videoData: movie.video_id,
                  },
                });
              };

              service.videoSubscription(movie.video_id).then((response) => {
                let videoSubDetails = response.data;
                let subFlag = true;
                service.userSubscription(uId).then((useResponse) => {
                  if (useResponse.success == true) {
                    var userSubDetails = useResponse.data;
                    if (useResponse.forcibleLogout === true) {
                      alert(
                        "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                      );
                      service.logoutAll(uId).then((res) => {
                        setTimeout(() => {
                          redirectToLogin();
                        }, 1000);
                      });
                    } else if (useResponse.session_expired === true) {
                      ToastsStore.warning("Sorry! Session Has Expired");
                      redirectToLogin();
                    } else {
                      videoSubDetails.map(function (subscription, index) {
                        if (useResponse.data.length == 0 && subFlag) {
                          subFlag = false;
                          freeVideoPopup(onClickYes, onClickNo);
                        } else {
                          let subscribedVideo = userSubDetails.filter(
                            (e) =>
                              e.sub_id == subscription.publisher_subscription_id
                          );
                          if (
                            subscribedVideo.length == 0 &&
                            index + 1 < videoSubDetails.length
                          ) {
                            return;
                          }
                          if (
                            subscribedVideo.length == 0 &&
                            subFlag &&
                            index + 1 == videoSubDetails.length
                          ) {
                            subFlag = false;
                            freeVideoPopup(onClickYes, onClickNo);
                          } else if (subFlag) {
                            subFlag = false;
                            service.setCookie("showId", ShowId, 10);
                            localStorage.setItem("ContinueWatching", "true");
                            localStorage.setItem(
                              "NoContinueWatchPopUp",
                              "true"
                            );
                            history.push({
                              pathname: "/videoDetails",
                              state: { movie: movie, show_id: ShowId },
                            });
                          }
                        }
                      });
                    }
                  }
                });
              });
            } else if (
              videoList &&
              videoList[0] &&
              videoList[0].free_video == true && //free video without any subscriptions
              videoList[0].subscriptions &&
              videoList[0].subscriptions.length == 0
            ) {
              let movie = videoList[0];
              service.setCookie("showId", ShowId, 10);
              localStorage.setItem("ContinueWatching", "true");
              localStorage.setItem("NoContinueWatchPopUp", "true");
              history.push({
                pathname: "/videoDetails",
                state: { movie: movie, show_id: ShowId },
              });
            }
          }
        } else {
          history.push("/404");
        }
      });
    }
  };

  // *********

  const onClickPlaybutton = (ShowId, videoId) => {
    ;
    if (ShowId && videoId) {
      service.getShowDetails(ShowId).then((response) => {
        if (response.success == true && response.data && response.data.videos) {
          let videoList = response.data.videos.filter(
            (e) => e.video_id == videoId
          );
          if (
            videoList &&
            videoList[0] &&
            videoList[0].screening_enabled != true &&
            videoList[0].free_video != true
          ) {
            let movie = videoList[0];
            service.videoSubscription(movie.video_id).then((response) => {
              let videoDetails = response.data;
              let subFlag = true;
              let uId = service.getCookie("guestUserId");
              let user_id = service.getCookie("userId");
              if (user_id) {
                uId = user_id;
              }
              service.userSubscription(uId).then((useResponse) => {
                if (useResponse.success == true) {
                  var userData = useResponse.data;
                  if (useResponse.forcibleLogout === true) {
                    alert(
                      "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                    );
                    service.logoutAll(uId).then((res) => {
                      setTimeout(() => {
                        redirectToLogin();
                      }, 1000);
                    });
                  } else if (useResponse.session_expired === true) {
                    ToastsStore.warning("Sorry! Session Has Expired");
                    redirectToLogin();
                  } else {
                    videoDetails.map(function (subscription, index) {
                      if (useResponse.data.length == 0 && subFlag) {
                        subFlag = false;
                        service.setCookie("showId", ShowId, 10);
                        service.setCookie("videoId", movie.video_id, 10);
                        history.push({
                          pathname: "/SubscriptionList",
                          state: {
                            videoData: movie.video_id,
                          },
                        });
                      } else {
                        let subscribedVideo = userData.filter(
                          (e) =>
                            e.sub_id == subscription.publisher_subscription_id
                        );
                        if (
                          subscribedVideo.length == 0 &&
                          index + 1 < videoDetails.length
                        ) {
                          return;
                        }
                        if (
                          subscribedVideo.length == 0 &&
                          subFlag &&
                          index + 1 == videoDetails.length
                        ) {
                          subFlag = false;
                          service.setCookie("showId", ShowId, 10);
                          service.setCookie("videoId", movie.video_id, 10);
                          history.push({
                            pathname: "/SubscriptionList",
                            state: {
                              videoData: movie.video_id,
                            },
                          });
                        } else if (subFlag) {
                          subFlag = false;
                          service.setCookie("showId", ShowId, 10);
                          localStorage.setItem("ContinueWatching", "true");
                          history.push({
                            pathname: "/videoDetails",
                            state: { movie: movie, show_id: ShowId },
                          });
                        }
                      }
                    });
                  }
                }
              });
            });
          } else {
            if (videoList && videoList[0]) {
              let movie = videoList[0];
              service.setCookie("showId", ShowId, 10);
              localStorage.setItem("ContinueWatching", "true");
              history.push({
                pathname: "/videoDetails",
                state: { movie: movie, show_id: ShowId },
              });
            }
          }
        } else {
          history.push("/404");
        }
      });
    }
  };

  return (
    <div
      className="individualBox slider__item"
      style={{ marginRight: "2px" }}
      title={
        localStorage.getItem("isLoggedIn") != "true" &&
        categoryName == "Watch for free"
          ? "Register to watch this content for free"
          : ""
      }
    >
      {" "}
      <ToastsContainer store={ToastsStore} />
      <div className="moviePoster">
        <img
          src={
            params && params.logo_thumb ? showsImageUrl + params.logo_thumb : ""
          }
          alt="End Note"
          className="css-4uwt2b eoho0pj9"
        />
        <div className="individualBoxContent">
          <div className="ibcDate">
            {params && params.day}
            {params && params.week}
          </div>
          <div className="divfree">
            {params && params.is_free_video && (
              <span>
                <img src={freeIcon} />
              </span>
            )}
            <h3 className="ibcTitle sliderItem__showname">
              {params && params.show_name ? params.show_name : ""}
            </h3>
          </div>
          <div className="ibcDate">
            {params && params.director ? params.director : ""}
            {params && params.director && params.year ? " . " : ""}
            {params && params.year ? params.year : ""}
            {params && params.duration_text && params.year ? " . " : ""}
            {params && params.director && params.duration_text && !params.year
              ? " . "
              : ""}
            {params && params.duration_text ? params.duration_text : ""}
          </div>
          {params.watched_percentage != undefined ? (
            <div className="progressbar__div">
              <div className="progressbar-border">
                <div
                  className="progressbar-grey"
                  style={
                    params.watched_percentage == 0
                      ? { height: "4px", width: "1%" }
                      : {
                          height: "4px",
                          width: `${params.watched_percentage}%`,
                        }
                  }
                ></div>
              </div>
            </div>
          ) : null}
          <div className="ibcMaker">
            <span className="ibcAuthor"></span>{" "}
            <span className="css-1a3tptd eoho0pj0"></span>
            <span></span>
          </div>
        </div>
      </div>
      <div
        className="blackOverlay"
        style={{ cursor: "pointer" }}
        onClick={() => {
          if (categoryId == "Continue-watching") {
            service.setCookie("showTitle", params.vanity_url, 10);
            onClickPlaybuttonNew(params.show_id, params.video_id);
          } else {
            history.push({
              pathname: `/movies/${params.vanity_url?.trim()}`,
              // pathname: "/home/movies",
              // search: encodeURI(`show_id=${params.show_id}`),
            });
          }
        }}
      >
        <svg
          viewBox="0 0 44 44"
          className="playSVG"
          style={{ cursor: "pointer" }}
        >
          <g transform="translate(1 1)" fillRule="evenodd" className="playG">
            <circle cx="21" cy="21" r="21" className="playCircle"></circle>
            <path
              fill="#FFFFFF"
              d="M30 21.5L15 29V14z"
              className="playPath"
            ></path>
          </g>
        </svg>
      </div>
      <div className="watch__list__button" data-tip data-for="registerTip">
        {params.watchlist_flag === 1 ? (
          <div>
            <Tooltip
              title="Remove from watchlist"
              arrow
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
            >
              <AddCircleOutlineIcon
                style={{ fontSize: "32px", color: "white", cursor: "pointer" }}
                onClick={() => {
                  removeFromMylistFunction(params);
                }}
              />
            </Tooltip>
            {watchListmsg == "Removed from watchlist" ? (
              <div className="div__tooltip">
                <TooltipMsg title={watchListmsg} />
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <Tooltip
              title="Add to watchlist"
              arrow
              classes={{
                tooltip: classes.customTooltip,
                arrow: classes.customArrow,
              }}
            >
              <AddCircleIcon
                style={{ fontSize: "32px", color: "white", cursor: "pointer" }}
                onClick={() => {
                  addtoMylistFunction(params);
                }}
              />
            </Tooltip>
            {watchListmsg == "Added to watchlist" ? (
              <div className="div__tooltip">
                <TooltipMsg title={watchListmsg} />
              </div>
            ) : null}
          </div>
        )}
      </div>
      {params.teaser && (
        <div className="trailer__eye">
          <Tooltip
            title="Play trailer"
            arrow
            classes={{
              tooltip: classes.customTooltip,
              arrow: classes.customArrow,
            }}
          >
            <VisibilityIcon
              style={{ fontSize: "23px", color: "white", cursor: "pointer" }}
              onClick={() => {
                onClickTralier(params.show_id);
              }}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default SliderItem;
