import React, { useState, useEffect } from "react";
import "./Banner.css";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { service } from "./service";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";
import TooltipMsg from "../Slider/Tooltip";

let url = "https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    backgroundColor: "#fff",
    color: "#000",
    fontSize: "12px",
    boxShadow: "1px 1px 9px",
  },
  customArrow: {
    color: "#fff",
  },
}));

const Newbanner = ({ bannerDetails, clickHandler }) => {
  const [apiResponse, setApiResponse] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [watchListmsg, setWatchListmsg] = useState(false);

  useEffect(() => {
    setApiResponse(bannerDetails);
    // updateBanner();
  }, [bannerDetails]);

  const updateBanner = () => {
    service.homeBannerDetails().then((response) => {
      if (response.success === true) {
        setApiResponse(response.data);
        
      }
    });
  };
console.log(bannerDetails,"bannerDetails");

  const addtoMylistFunction = (show) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      service.addToMyPlayList(show.show_id, 1).then((response) => {
        clickHandler();
        updateBanner();
        setWatchListmsg("Added to watchlist");
        setTimeout(function () {
          setWatchListmsg(false);
        }, 1000);
      });
    } else {
      dispatch({ type: "SIGN_IN_BLOCK" });
    }
  };
  const removeFromMylistFunction = (show) => {
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      service.addToMyPlayList(show.show_id, 0).then((response) => {
        clickHandler();
        updateBanner();
        setWatchListmsg("Removed from watchlist");
        setTimeout(function () {
          setWatchListmsg(false);
        }, 2000);
      });
    } else {
      dispatch({ type: "SIGN_IN_BLOCK" });
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  };

  return (
    <div className="jwm__banner sidePadExs">
      <div className="dotsSlider">
        <div className="autoSlider">
          <SlickSlider {...settings}>
            {apiResponse.map((item, index) => {
              return (
                <div className="individualBox" key={index}>
                  {
                    console.log(url + item.banner,"url + item.banner")
                    
                  }
                  <div className="moviePoster">
                    {
                      console.log(url + item.banner3,"url + item.banner3")
                      
                    }
                    <img
                      src={url + item.banner3}
                      alt=""
                      className="bannerImg"
                    />
                    <img
                      src={url + item.banner}
                      alt=""
                      className="bannerImgMobile"
                    />
                    <div className="asOverlayContent">
                      <div className="css-nnej1 e797jlf6">
                        <div>
                          <div className="asHead">{item.category_name[0]}</div>
                        </div>
                        <div>
                          <h2 className="asTitle">{item.video_title}</h2>
                        </div>
                        <div className="asContent">
                          <h3 className="asMaker">
                            {item.director && (
                              <span className="asAuthor">
                                Directed by {item.director}{" "}
                              </span>
                            )}
                            <span className="css-r891bz ezubom41">
                              {item.year}
                            </span>
                          </h3>
                          <div className="asDescription">
                            <p>{item.synopsis}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hpBannerPlay">
                      <div className="bcPlayButtonSize">
                        <div className="blackOverlay">
                          <a>
                            <svg
                              style={{ width: "75px", cursor: "pointer" }}
                              viewBox="0 0 44 44"
                              className="playSVG playSVG__mobile"
                              onClick={() => {
                                history.push({
                                  pathname: `/movies/${item.vanity_url?.trim()}`,
                                  // pathname: "/home/movies",
                                  // search: encodeURI(`show_id=${item.show_id}`),
                                });
                              }}
                            >
                              <g
                                transform="translate(1 1)"
                                fillRule="evenodd"
                                className="playG"
                              >
                                <circle
                                  cx="21"
                                  cy="21"
                                  r="21"
                                  className="playCircle"
                                ></circle>
                                <path
                                  fill="#FFFFFF"
                                  d="M30 21.5L15 29V14z"
                                  className="playPath"
                                ></path>
                              </g>
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="watch__list__button">
                      {item.watchlist_flag === 1 ? (
                        <div>
                          <Tooltip
                            title="Remove from watchlist"
                            arrow
                            classes={{
                              tooltip: classes.customTooltip,
                              arrow: classes.customArrow,
                            }}
                          >
                            <AddCircleOutlineIcon
                              style={{
                                fontSize: "32px",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                removeFromMylistFunction(item);
                              }}
                            />
                          </Tooltip>
                          {watchListmsg == "Removed from watchlist" ? (
                            <div style={{ position: "relative" }}>
                              <TooltipMsg title={watchListmsg} />
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <div>
                          <Tooltip
                            title="Add to watchlist"
                            arrow
                            classes={{
                              tooltip: classes.customTooltip,
                              arrow: classes.customArrow,
                            }}
                          >
                            <AddCircleIcon
                              style={{
                                fontSize: "32px",
                                color: "white",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                addtoMylistFunction(item);
                              }}
                            />
                          </Tooltip>
                          {watchListmsg == "Added to watchlist" ? (
                            <div style={{ position: "relative" }}>
                              <TooltipMsg title={watchListmsg} />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </SlickSlider>
        </div>
      </div>
    </div>
  );
};

export default Newbanner;
