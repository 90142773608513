import React, { useState, useEffect } from "react";
import "./ShowEpisode.css";
import { service } from "./service";

let url = "https://gizmeon.mdc.akamaized.net/thumbnails/thumbnails/";
function ShowEpisode({ apiData, onEpisodePlay }) {
  let isLoggedIn = localStorage.getItem("isLoggedIn");
  useEffect(() => {}, []);
  var items = [];
  apiData.videos.map((itemvalue) => {
    items.push(itemvalue);
  });

  return (
    <div className="showingCategory">

      <div className="showingCategoryWrapper">

        <div className="showingEpisodes">

          <div className="showingEpisodesTitleWrap">
            <>
              <a href="#">
                <h2 className="showingEpisodesTitle">Show Episodes</h2>
              </a>
            </>
          </div>

          <div>

            <div className="thumbnail__grid__containers">

              {items.map((list) => (
                <div className="episode__wrapper">

                  <div className="thumbnail__wrappers">

                    <div className="thumbnail__containers">
                      <img
                        src={url + list.thumbnail_350_200}
                        className="thumbnail__images"
                      />
                    </div>

                    <div className="thumbnail__overlay__containers">
                      <div className="vl" />
                      <div className="episodenumber">{list.video_order}</div>
                      <div className="thumbnail__overlay__text__containers">
                        {list && list.video_title && (
                          <h3
                            className="thumbnail__titles"
                            title={list.video_title}
                          >
                            {list.video_title}
                          </h3>
                        )}
                        <div className="thumbnail__descs">
                          <span className="thumbnail__desc__spans">
                            {apiData && apiData.director
                              ? apiData.director
                              : ""}
                            {apiData && apiData.director && apiData.year
                              ? " , "
                              : ""}
                            {apiData && apiData.year ? apiData.year : ""}
                            {apiData && apiData.duration_text && apiData.year
                              ? " , "
                              : ""}
                            {apiData && apiData.duration_text
                              ? apiData.duration_text
                              : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                   
                    <div className="thumbnail__playButtons">
                      <svg
                        viewBox="0 0 44 44"
                        className="playSVG playSVGThumbs"
                        style={{ width: "40px" }}
                        onClick={() => {
                          onEpisodePlay(list);
                        }}
                      >
                        <g
                          transform="translate(1 1)"
                          fillRule="evenodd"
                          className="playG"
                        >
                          <circle
                            cx="21"
                            cy="21"
                            r="21"
                            className="playCircle"
                          ></circle>
                          <path fill="#FFFFFF" d="M30 21.5L15 29V14z"></path>
                        </g>
                      </svg>
                    </div>
                  </div>

                  <div style={{ backgroundColor: "white" }}>
                    <div className="subscription__buttons__container">
                      {list.screening_enabled != true &&
                      list.subscriptions.length > 0
                        ? list.subscriptions.map((item, index) => {
                            return (
                              <div key={index}>
                                {item.subscription_type == 6 && (
                                  <button
                                    className="subscription_button"
                                    onClick={() => {
                                      onEpisodePlay(list);
                                    }}
                                  >
                                    Rent
                                  </button>
                                )}
                                {item.subscription_type == 1 && (
                                  <button
                                    className="subscription_button"
                                    onClick={() => {
                                      onEpisodePlay(list);
                                    }}
                                  >
                                    Buy
                                  </button>
                                )}
                                {item.subscription_type == 3 && (
                                  <button
                                    className="subscription_button"
                                    onClick={() => {
                                      onEpisodePlay(list);
                                    }}
                                  >
                                    Subscribe
                                  </button>
                                )}
                              </div>
                            );
                          })
                        : null}
                      {list.screening_enabled != true &&
                      list.free_video == true ? (
                        <button
                          className="subscription_button"
                          style={{ backgroundColor: "green" }}
                          onClick={() => {
                            onEpisodePlay(list);
                          }}
                        >
                          Free
                        </button>
                      ) : null}
                      {list.screening_enabled == true ? (
                        <button
                          className="subscription_button"
                          style={{ backgroundColor: "green" }}
                          onClick={() => {
                            onEpisodePlay(list);
                          }}
                        >
                          Screening room video
                        </button>
                      ) : null}
                    </div>
                    <div className="episode_synopsis">
                      <p>{list.video_description}</p>
                    </div>
                  </div>

                </div>
              ))}

            </div>

          </div>
        </div>

      </div>

    </div>
  );
}
export default ShowEpisode;
