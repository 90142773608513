import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { service } from "../../Network/service";
import { convertAdUrl } from "../../Utils/utils";
import $ from "jquery";
import closepanel from "../../img/icon-closepanel.png";
import settingicon from "../../images/settingicons.png";
import { ToastsContainer, ToastsStore } from "react-toasts";
import "./Video.css";

var details = [];
var videoDetailUtils = [];

let stingerUrl =
  "https://gizmeon.mdc.akamaized.net/capitalmotion-static-files/stinger_16x9_23-98_L-R.mp4";

const VideoDetails = (props) => {
  var videoStarted = false;
  var categories = "";
  var isContinueWatching = localStorage.getItem("ContinueWatching");
  var NoContinueWatchPopUp = localStorage.getItem("NoContinueWatchPopUp");
  let history = useHistory();
  const [categoryName, setCategoryNames] = useState("");
  let isFreeVideo = false;
  const [videoPlayer, setVideoPlayer] = useState(
    <video
      id="content_video"
      className="video-js vjs-default-skin mainPlayer"
      controls
      preload="auto"
    >
      {" "}
      <source src="" type="video/mp4" />{" "}
    </video>
  );

  useEffect(() => {
    
    document.getElementById("scroll-top").scroll(0, 0);
    window.scrollTo(0, 0);
    localStorage.setItem("fromVideoplayer", true);
    let subtitles;
    let watchedDuration;
    if (props.location.state) {
      let show_details = "";
      let showId = "";
      if (history.location.state.show_id) {
        showId = history.location.state.show_id;
      }
      localStorage.setItem("showId", showId);
      service.getShowDetails(showId).then((response) => {
        let dataDetails = response.data.videos;
        let showDetails = response.data;
        dataDetails.map((item, index) => {
          if (item.video_id === history.location.state.movie.video_id) {
            show_details = item;
          }
        });
        watchedDuration = history.location.state.movie.watched_duration
          ? history.location.state.movie.watched_duration
          : 0;
        subtitles = show_details.subtitles;
        // if (history.location.state.movie.premium_flag == "0") {
        //   show_details = dataDetails[0];
        // }
        showDetails.categories &&
          showDetails.categories.map((item, index) => {
            categories = categories + item.category_name + ",";
          });
        setCategoryNames(categories);
        details = show_details;
        videoDetailUtils = showDetails;
        service.playerToken().then((tokenResponse) => {
          let arr = show_details.video_name.split("/").reverse();
          let newURL =
            "https://poppo.tv/playlist/playlist.m3u8?id=" +
            arr[1] +
            "&token=" +
            tokenResponse.data.data +
            "&type=video" +
            "&pubid=" +
            process.env.REACT_APP_PUBID;
          let videoElem =
            "content_video" + show_details.video_id + new Date().valueOf();
          setVideoPlayer(
            <video
              id={videoElem}
              className="video-js vjs-default-skin vjs-big-play-centered mainPlayer"
              controls
              preload="auto"
              autoPlay
              x-webkit-airplay="deny"
              crossOrigin="anonymous"
            >
              <source
                // src={stingerUrl}
                // type="video/mp4"
                src={newURL}
                type="application/x-mpegURL"
              />
              {subtitles &&
                subtitles.map((item) => {
                  if (item.code == "eng") {
                    return (
                      <track
                        label={item.language_name}
                        kind="subtitles"
                        srcLang={item.short_code}
                        src={item.subtitle_url}
                        default
                      />
                    );
                  } else {
                    return (
                      <track
                        label={item.language_name}
                        kind="subtitles"
                        srcLang={item.short_code}
                        src={item.subtitle_url}
                      />
                    );
                  }
                })}
            </video>
          );

          //With or Without Ads
          
          let uId = service.getCookie("guestUserId");
          let user_id = service.getCookie("userId");
          if (user_id) {
            uId = user_id;
          }
          service.videoSubscription(show_details.video_id).then((response) => {
            let videoSubDetails = response.data;
            let subFlag = true;
            service.userSubscription(uId).then((userResponse) => {
              if (userResponse.success == true) {
                var userSubDetails = userResponse.data;
                if (userResponse.forcibleLogout === true) {
                  alert(
                    "Sorry, You’ve reached the maximum Device limit. Please log in again!"
                  );
                  service.logoutAll(uId).then((res) => {
                    setTimeout(() => {
                      redirectToLogin();
                    }, 1000);
                  });
                } else if (userResponse.session_expired === true) {
                  ToastsStore.warning("Sorry! Session Has Expired");
                  redirectToLogin();
                } else {
                  if (userSubDetails && userSubDetails.length != 0) {
                    let subCount =
                      userSubDetails &&
                      userSubDetails.filter(
                        (e) =>
                          e.subscription_type_id == 4 ||
                          e.subscription_type_id == 3
                      );
                    if (
                      subCount &&
                      subCount.length > 0 &&
                      show_details.free_video == true &&
                      show_details.subscriptions &&
                      show_details.subscriptions.length === 0
                    ) {
                      isFreeVideo = true;
                    } else {
                      videoSubDetails.map(function (subscription, index) {
                        let subscribedVideo = userSubDetails.filter(
                          (e) =>
                            e.sub_id == subscription.publisher_subscription_id
                        );
                        if (
                          subscribedVideo.length == 0 &&
                          index + 1 < videoSubDetails.length
                        ) {
                          return;
                        }
                        if (
                          subscribedVideo.length == 0 &&
                          subFlag &&
                          index + 1 == videoSubDetails.length
                        ) {
                          subFlag = false;
                        } else if (subFlag) {
                          subFlag = false;
                          isFreeVideo = true;
                        }
                      });
                    }
                  }
                  if (show_details.screening_enabled == true) {
                    isFreeVideo = true;
                  }
                  let adUrl =
                    isFreeVideo == false
                      ? convertAdUrl(show_details, videoDetailUtils)
                      : null;
                  if (
                    isContinueWatching == "true" &&
                    watchedDuration &&
                    watchedDuration != 0
                  ) {
                    localStorage.removeItem("ContinueWatching");
                    isContinueWatching = false;
                    if (NoContinueWatchPopUp == "true") {
                      localStorage.removeItem("NoContinueWatchPopUp");
                      window.playMainPlayer(
                        adUrl,
                        videoElem,
                        show_details.video_id,
                        details,
                        true,
                        watchedDuration
                      );
                    } else if (
                      window.confirm("Continue from where you stopped?")
                    ) {
                      window.playMainPlayer(
                        adUrl,
                        videoElem,
                        show_details.video_id,
                        details,
                        true,
                        watchedDuration
                      );
                    } else {
                      window.playMainPlayer(
                        adUrl,
                        videoElem,
                        show_details.video_id,
                        details,
                        false
                      );
                    }
                  } else {
                    localStorage.removeItem("ContinueWatching");
                    window.playMainPlayer(
                      adUrl,
                      videoElem,
                      show_details.video_id,
                      details,
                      false
                    );
                  }

                  setTimeout(() => {
                    const image = document.createElement("img");
                    image.src = settingicon;
                    image.className = "videoSettingsIcon";
                    var parent = document.querySelector(
                      ".vjs-resolution-button"
                    );
                    if (parent && parent.querySelector(".videoSettingsIcon")) {
                    } else {
                      if (document.querySelector(".vjs-resolution-button")) {
                        document
                          .querySelector(".vjs-resolution-button")
                          .appendChild(image);
                      }
                    }
                  }, 3000);
                }
              }
            });
          });
        });
      });
    }
    return () => {
      try {
        window.disposeMainPlayer();
      } catch (e) {
      }
    };
  }, []);

  const redirectToLogin = () => {
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    localStorage.removeItem("deviceAnalytics");
    service.setCookie("isLoggedIn", false, 30);
    localStorage.removeItem("isLoggedIn");
    service.eraseCookie("showId");
    service.eraseCookie("isScreeningUser");
    // dispatch({ type: "LOGOUT" });
    // setMouseHover(false);
    service.eraseCookie("userName");
    service.eraseCookie("userId");
    service.eraseCookie("userEmail");
    service.eraseCookie("subscriptionId");
    sessionStorage.removeItem("applaunch");
    setTimeout(() => {
      window.location.href = "/signin";
    }, 1500);
  };

  window.onVideoPlay = (vd, currentTime) => {
    //start and resume
    let event = videoStarted == true ? "POP09" : "POP02";
    videoStarted = true;
    service
      .onVideoPlayFunction(vd, event, categoryName, currentTime)
      .then((response) => {});
    const ctime = 600;
  };

  window.onVideoPlaying = (vd, currentTime) => {
    let event = "POP03";
    service
      .onVideoPlayFunction(vd, event, categoryName, currentTime)
      .then((response) => {});
  };

  window.onVideoPause = (vd, currentTime) => {
    let event = "POP04";
    service
      .onVideoPlayFunction(vd, event, categoryName, currentTime)
      .then((response) => {});
  };
  window.onVideoEnd = (vd, currentTime) => {
    let event = "POP05";
    service
      .onVideoPlayFunction(vd, event, categoryName, currentTime)
      .then((response) => {
        history.push({
          pathname: "/home",
        });
      });
  };

  const closeVideo = () => {
    let showId = service.getCookie("showId");
    let showTitle = service.getCookie("showTitle");
    if (details.length != 0) {
      if (
        showTitle &&
        history.location.state &&
        history.location.state.prevUrl &&
        history.location.state.prevUrl == "/success"
      ) {
        history.push({
          pathname: `/movies/${showTitle?.trim()}`,
          // pathname: "/home/movies",
          // search: encodeURI(`show_id=${showId}`),
        });
      } else if (showTitle) {
        history.push({
          pathname: `/movies/${showTitle?.trim()}`,
          // pathname: "/home/movies",
          // search: encodeURI(`show_id=${showId}`),
        });
      } else {
        history.push({
          pathname: "/home",
        });
      }
    }
  };
  return (
    <div>
      <div
        className="video_container"
        id="video-id-container"
        style={{ position: "relative" }}
      >
        <div
          className="closingButton"
          style={{ cursor: "pointer" }}
          onClick={() => closeVideo()}
        >
          <img
            src={closepanel}
            style={{ width: "35px", padding: "3px", opacity: ".5" }}
            className="close-video-button"
          />
        </div>
        <div className="liveVideoWrapper">{videoPlayer}</div>
      </div>
      <ToastsContainer store={ToastsStore} />
    </div>
  );
};
export default VideoDetails;
