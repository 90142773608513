import React, { useState, useEffect } from "react";
import "./ShowDetailStyle.css";

let url = "https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/";
function Cast({ apiData, type }) {
  useEffect(() => {}, []);
  var items = [];
  if (type == "Cast") {
    apiData.cast &&
      apiData.cast.map((itemvalue) => {
        items.push(itemvalue);
      });
  } else {
    apiData.crew &&
      apiData.crew.map((itemvalue) => {
        items.push(itemvalue);
      });
  }

  return (
    <div className="showingCategory">
      <div className="showingCategoryWrapper">
        <div className="showingCastCrew">
          <div className="showingAwardsTitleWrap">
            {items.length !== 0 ? (
              <>
                <a href="">
                  <h2 className="showingAwardsTitle">{type}</h2>
                </a>
   
              </>
            ) : (
              ""
            )}
          </div>

          <ul className="showingCastCrewUL">
            {items.map((list) => (
              <div className="showingCastCrewBox">
                <li className="showingCastCrewLI">
                  <div className="showingCastCrewImageWrapper">
                    {list.imdb_link ? (
                      <a href={list.imdb_link} target="_blank">
                        <div className="showingCastCrewImgBox">
                          <img
                            src={url + list.image}
                            className="showingCastCrewImage"
                            style={{
                              WebkitFilter: "grayscale(1)",
                              filter: "gray",
                            }}
                          />
                        </div>
                      </a>
                    ) : (
                      <a>
                        <div className="showingCastCrewImgBox">
                          <img
                            src={url + list.image}
                            className="showingCastCrewImage"
                            style={{
                              WebkitFilter: "grayscale(1)",
                              filter: "gray",
                            }}
                          />
                        </div>
                      </a>
                    )}
                    <div className="castPersonalisationOverlay">
                      <div className="personalisationSeperate">
                        <div title="Favorite">
                          <div>
                            <div
                              className="personalisationCursor"
                              style={{ display: "none" }}
                            >
                              <div
                                width="28px"
                                className="personalisationBtnBox"
                              >
                                <svg
                                  viewBox="0 0 28 28"
                                  fill="#FFFFFF"
                                  className="bcPlayButtonSVG"
                                >
                                  <path d="M14 28a14 14 0 1 1 0-28 14 14 0 0 1 0 28zm3.64-21C15.34 7 14 8.9 14 8.9S12.67 7 10.36 7A4.27 4.27 0 0 0 6 11.16c0 4.04 4.95 7.9 7.2 9.58a1.36 1.36 0 0 0 1.6 0c2.25-1.68 7.2-5.54 7.2-9.57C22 8.86 20.05 7 17.64 7z"></path>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="showingCastCrewImageWrapper">
                    {list.imdb_link ? (
                      <a href={list.imdb_link} target="_blank">
                        <div className="showingCastCrewPadding">
                          <div className="showingCastCrewBox">
                            <span lang="en" className="showingCastCrewName">
                              {list.name}
                            </span>
                          </div>
                          <span className="showingCastCrewPosition">
                            {list.role}
                          </span>
                        </div>
                      </a>
                    ) : (
                      <a>
                        <div className="showingCastCrewPadding">
                          <div className="showingCastCrewBox">
                            <span lang="en" className="showingCastCrewName">
                              {list.name}
                            </span>
                          </div>
                          <span className="showingCastCrewPosition">
                            {list.role}
                          </span>
                        </div>
                      </a>
                    )}
                  </h3>
                </li>
              </div>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Cast;
