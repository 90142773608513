import React, { useState, useEffect } from "react";
import "./ShowDetailStyle.css";
import { useHistory } from "react-router-dom";

let url = "https://gizmeon.mdc.akamaized.net/thumbnails/show_logo/";

function SimilarShow({ apiData }) {
  const history = useHistory();
  useEffect(() => {}, []);
  var items = [];
  apiData.categories.map((itemvalue) => {
    items.push(itemvalue);
  });
  return (
    <div className="showingCategory">
      <div className="showingCategoryWrapper">
        <section className="showingCategorySection">
          <h2 className="showingCategorTitle">Showing as</h2>

          <ul className="showingCategoryUL">
            {items.map((list) => (
              <li className="showingCategoryLI">
                <a href="" className="showingCategoryBox">
                  <div className="showingCategoryBoxImage">
                    <img src={url + list.image} />
                  </div>
                  <div
                    className="showingCategoryBoxContent"
                    onClick={() => {
                      history.push({
                        pathname: "/home/categorylist",
                        search: encodeURI(
                          `category_id=${list.category_id}&category_name=${list.category_name}`
                        ),
                      });
                    }}
                  >
                    {list.category_name.toUpperCase()}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </div>
  );
}
export default SimilarShow;
